import Autocomplete from './autocomplete_controller'

export default class extends Autocomplete {

	initialize() {
		if (this.hasFieldTarget) {
			$('.traits').on('cocoon:after-remove', () => {
				this.refresh()
			})
		}
	}

	onShow() {
		let elem = this.element

		if (elem.value == '') {
			$(elem).closest('.nested-fields').find('.ts-control input').focus()
		}
	}

	onChange() {
		let elem = this.element

		if (elem.value != '') {
			this.refresh()
		}
	}

	onBlur() {
		let elem = this.element

		if (elem.value == '') {
			let wrapper = $(elem).closest('.nested-fields')

			if (wrapper.find('.dynamic').length) {
				wrapper.remove()
			} else {
				wrapper.find('.remove_fields').trigger('click')
			}
		}
	}

	// FIXME: this doesn't work yet
	renderTemplates() {
		let _this = this

		return {
			option: function(data, escape) {
				return `<div class="d-flex justify-content-between">
					<span>
						${ escape(data.name) }
					</span>
					<a href="${ _this.createUrlValue }/${ escape(data.id) }" title="Removes this option from all competitors globally" data-controller="tooltip" data-toggle="tooltip" data-placement="left" data-method="delete" class="mr-2">
						<i class="fa-regular fa-trash-alt icon-color"></i>
					</a>
				</div>`
			}
		}
	}

	refresh() {
		Rails.fire(this.element.form, 'submit')
	}
}
