import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

	connect() {
		$(this.element).find('.form-control').focus()
	}

	submit() {
		Rails.fire(this.element, 'submit')
	}

	abort(event) {
		if (event.keyCode === 27) {
			$(this.element).find('.cancel')[0].click()
		}
	}
}
