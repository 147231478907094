import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
	static targets = ['entries', 'pagination']

	initialize() {
		if (this.hasEntriesTarget) {
			this.intersectionObserver = new IntersectionObserver(
				entries => this.processIntersectionEntries(entries), {
					threshold: [0, 1.0]
				}
			)
		}
	}

	connect() {
		if (this.hasPaginationTarget) {
			this.intersectionObserver.observe(this.paginationTarget)
		}
	}

	disconnect() {
		if (this.hasPaginationTarget) {
			this.intersectionObserver.unobserve(this.paginationTarget)
		}
	}

	processIntersectionEntries(entries) {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				this.loadMore()
			}
		})
	}

	loadMore() {
		const next = this.paginationTarget.querySelector('a[rel=next]')

		if (next) {
			get(next.href, {
				responseKind: 'turbo-stream'
			})
		} else {
			this.paginationTarget.innerHTML = ''
		}
	}
}
