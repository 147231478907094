import { Controller } from '@hotwired/stimulus'
import { debounce } from 'lodash'

export default class extends Controller {
	static targets = ['search', 'icons']
	static values = {
		tokenEndpoint: {
			type: String,
			default: 'https://api.fontawesome.com/token'
		},
		url: {
			type: String,
			default: 'https://api.fontawesome.com'
		},
		version: {
			type: String,
			default: '6.5.0'
		},
		limit: {
			type: Number,
			default: 15
		},
		styles: {
			type: Array,
			default: ['brands', 'regular']
		}
	}

	connect() {
		this.search = debounce(this.search, 500)
	}

	load() {
		if (!this.hasIconsTarget) return

		fetch(this.urlValue, {
			method: 'POST',
			body: this.query
		})
		.then(response => response.json())
		.then(json => {
			this.clear()
			this.renderIcons(json)
		})
		.catch(e => console.error(e))
	}

	clear() {
		this.iconsTarget.innerHTML = ''
	}

	choose() {
		const data = this.element.dataset
		const icon = `fa-${data.style} fa-${data.icon}`

		let button = $(this.element).closest('.dropdown').find('.btn')

		if (button) {
			$(button).find('i').attr('class', icon)
			$(button).find('input.hidden').val(icon)
		}
	}

	search(event) {
		if (event.key === 'Enter' || event.key === 'Escape') {
			event.preventDefault()
		} else {
			this.load()
		}
	}

	preventSubmit(event) {
		event.preventDefault()
	}

	renderIcons(json) {
		const icons = this.isEmptySearch ? json.data.release.icons : json.data.search

		icons.forEach(icon => {
			icon.styles.forEach(style => {
				if (this.stylesValue.includes(style)) {
					const newIcon = this.iconTemplate(style, icon)
					$(this.iconsTarget).append(newIcon)
				}
			})
		})
	}

	iconTemplate(style, icon) {
		return `<a class="dropdown-item p-2 rounded" style="cursor: pointer" data-controller="icon-picker" data-action="icon-picker#choose" data-style="${style}" data-icon="${icon.id}"><i class="fa-${style} fa-${icon.id}"></i></a>`
	}

	get query() {
		if (this.isEmptySearch) {
			return `query { release(version: "${this.versionValue}") { icons { id, styles } } }`
		} else {
			return `query { search(version: "${this.versionValue}", query: "${this.searchTerm}", first: ${this.limitValue}) { id, styles }}`
		}
	}

	get isEmptySearch() {
		return this.searchTerm === ''
	}

	get searchTerm() {
		return this.searchTarget.value.trim()
	}

	// get apiKey() {
	// 	return $('meta[name="fa-token"]').attr('content')
	// }
	//
	// async fetchAccessToken() {
	// 	try {
	// 		const response = await fetch(this.tokenEndpointValue, {
	// 			method: 'POST',
	// 			headers: {
	// 				Authorization: `Bearer ${this.apiKey}`
	// 			}
	// 		})
	//
	// 		const json = await response.json()
	// 		return json.access_token
	//
	// 	} catch (e) {
	// 		console.error(e)
	// 	}
	// }
	//
	// async loadProIcons() {
	// 	if (!this.hasIconsTarget) return
	//
	// 	try {
	// 		const token = await this.fetchAccessToken()
	// 		const response = await fetch(this.urlValue, {
	// 			method: 'POST',
	// 			body: this.query,
	// 			headers: {
	// 				Authorization: `Bearer ${token}`
	// 			}
	// 		})
	//
	// 		const json = await response.json()
	//
	// 		this.clear()
	// 		this.renderIcons(json)
	//
	// 	} catch (e) {
	// 		console.error(e)
	// 	}
	// }
}
