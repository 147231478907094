import { Controller } from '@hotwired/stimulus'
import autosize from 'autosize';

export default class extends Controller {
	static targets = ['field']

	connect() {
		if (this.hasFieldTarget) {
			autosize(this.fieldTarget);
		}
	}
}
