import { Controller } from '@hotwired/stimulus'
import Plyr from 'plyr'

export default class extends Controller {
	static targets = ['player']

	connect() {
		if (this.hasPlayerTarget) {
			this.player = new Plyr(this.playerTarget)
		}
	}

	disconnect() {
		if (this.hasPlayerTarget) {
			this.player.destroy()
		}
	}
}
