import { Controller } from '@hotwired/stimulus'
import { get, post } from '@rails/request.js'
import TomSelect from 'tom-select'

export default class extends Controller {
	static targets = ['field']

	static values = {
		valueField: { type: String, default: 'id' },
		labelField: { type: String, default: 'name' },
		searchField: { type: String, default: 'name' },
		searchUrl: String,
		createUrl: String,
		model: String,
		plugins: Array
	}

	connect() {
		if (this.hasFieldTarget) {
			this.initializeTomSelect()
		}
	}

	initializeTomSelect() {
		new TomSelect(this.fieldTarget, {
			persist: false,
			preload: 'focus',
			valueField: this.valueFieldValue,
			labelField: this.labelFieldValue,
			searchField: this.searchFieldValue,
			plugins: this.pluginsValue,
			render: this.renderTemplates(),
			create: this.createOption.bind(this),
			load: this.loadOptions.bind(this),
			onInitialize: this.onShow.bind(this),
			onChange: this.onChange.bind(this),
			onBlur: this.onBlur.bind(this)
		})
	}

	createOption(input, callback) {
		let elem = this.element
		let data = new FormData()

		data.append(`${this.modelValue}[${this.labelFieldValue}]`, input)

		post(this.createUrlValue, {
			query: data,
			responseKind: 'json'
		}).then(response =>
			response.json
		).then(json => {
			if (json.errors) {
				alert(Object.values(json.errors).join("\n"))
			} else {
				callback({
					[this.valueFieldValue]: json[this.valueFieldValue],
					[this.labelFieldValue]: json[this.labelFieldValue]
				})
			}
		}).catch(() =>
			callback()
		)
	}

	loadOptions(query, callback) {
		let url = this.searchUrlValue + '?term=' + encodeURIComponent(query)

		get(url, {
			responseKind: 'json'
		}).then(response =>
			response.json
		).then(json =>
			callback(json)
		).catch(() =>
			callback()
		)
	}

	onShow() {
	}

	onChange() {
	}

	onBlur() {
	}

	renderTemplates() {
	}

	close() {
		if (event.keyCode === 27) {
			this.onBlur()
		}
	}
}
