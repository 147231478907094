import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	static targets = ['frame']

	connect() {
		this.frameTarget.onload = this.resize.bind(this)
	}

	resize(event) {
		this.frameTarget.style.height = (this.frameTarget.contentWindow.document.body.scrollHeight + 40) + 'px'
	}
}
