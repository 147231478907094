import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {
	static targets = ['position']

	connect() {
		if (this.hasPositionTarget) {
			this.sortable()
		}
	}

	sortable() {
		new Sortable(this.element, {
			group: 'shared',
			handle: '.sortable-handle',
			animation: 150,
			onEnd: this.onEnd.bind(this)
		})
	}

	onEnd(event) {
		this.positionTargets.forEach((item, index) => {
			item.value = index
		})
	}
}
