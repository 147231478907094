// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@hotwired/turbo-rails")
require("@rails/ujs").start()
require("@rails/activestorage").start()
require("@rails/actiontext")
require("local-time").start()
require("@nathanvda/cocoon")
require("trix")
require("channels")

window.Rails = Rails

import 'controllers'
import 'bootstrap'
import 'data-confirm-modal'

$(document).on('turbo:load', () => {
	$('.sidebar-toggle').off('click').click((e) => {
		$('body').toggleClass('sidebar-toggled')
	})
})

// Dropdown menu with class allow-focus won't close on click of checkbox
$(document).on('turbo:load', () => {
	$(document).on('click', '.allow-focus', (e) => {
		e.stopPropagation()
	})
})

$(document).on('turbo:load', () => {
	$(document).on('click', '.disabled', (e) => {
		$(e.target).closest('.editable').removeClass('editing');
		$(e.target).removeAttr('disabled');
		$(e.target).removeClass('disabled');
		$(e.target).parent().removeClass('disabled');
		$(e.target).focus();
	})
})

/* Toast activated */
$(document).on('turbo:load', () => {
	$('.toast').toast('show').on('hidden.bs.toast', function() {
		$('.toast').remove();
	});
})

dataConfirmModal.setDefaults({
	commit: 'Continue',
	cancel: 'Cancel',
	commitClass: 'btn-outline-danger',
	cancelClass: 'btn-outline-primary'
});
