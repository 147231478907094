import { Controller } from '@hotwired/stimulus'
import TomSelect from "tom-select";
  
export default class extends Controller {
	static targets = ['form']
  
	updateContent(event) {
		Rails.fire(this.formTarget, 'submit');
	}
  
	updateName(event) {
		$("#check-mark").remove();
		$("#spinner").css('display', 'block')
		var elem = document.getElementById("edit_competitor_" + this.element.dataset.editableId)
		Rails.fire(elem, 'submit');
	}
  
	escClose(event) {
		var editableAttr = this.element.dataset.attr
		if (event.key === 'Escape') {
			if (editableAttr == 'competitor_name') {
				if ($('#competitor-name-form').css('display') == 'block') {
					$("#competitor-name-form").css('display', 'none')
					$("#competitor-name").css('display', 'block')
				}
			} else if (editableAttr == 'competitor_description') {
				if ($('#description-form').css('display') == 'block') {
					$("#description-form").css('display', 'none')
					$("#description").css('display', 'block')
				}
			}
		}
	}
  
	formClose(event) {
		var editableAttr = this.element.dataset.attr
		if (editableAttr == 'competitor_name') {
			if ($('#competitor-name-form').css('display') == 'block') {
				$("#competitor-name-form").css('display', 'none')
				$("#competitor-name").css('display', 'block')
			}
		} else if (editableAttr == 'competitor_description') {
			if ($('#description-form').css('display') == 'block') {
				$("#description-form").css('display', 'none')
				$("#description").css('display', 'block')
			}
		}
	}
}
