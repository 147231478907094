import { Controller } from '@hotwired/stimulus'
import Trix from 'trix'

export default class extends Controller {
	static targets = ['content']
	static outlets = ['attachment']

	connect() {
		if (this.hasContentTarget) {
			this.contentTarget.addEventListener('trix-file-accept', this.handleTrixFileAccept.bind(this))
		}
	}

	handleTrixFileAccept(event) {
		const file = event.file

		if (file && this.hasAttachmentOutlet) {
			const isMedia = file.type.startsWith('image')

			if (!isMedia) {
				event.preventDefault()
				this.attachmentOutlet.upload(file)
			}
		}
	}
}
